/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * Fetch courses.
   *
   * @param {Event} e
   */
  async fetchCourses(e) {
    const field = this.element.querySelector('[name="fields[courseCode]"]');
    const response = await fetch(e.params.url);
    const result = await response.json();

    result.forEach((course) => {
      const option = document.createElement('option');
      option.text = course.name;
      option.value = course.id;
      field.add(option);
    });
  }
}
